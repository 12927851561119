import React from "react"
import Layout from "../../components/layout/Layout.js"
import { BlogRow, Row } from "../../components/row/Row.js"
import {
  SubHeading,
  BlogTitle,
} from "../../components/title/Title.js"
import { Column50 } from "../../components/column/Column.js"
import { ButtonContainer, Contact } from "../../components/button/Button.js"
import Seo from "../../components/seo"
import img1 from "../../static/content-governance-template.png"
import { Breadcrumb } from '../../components/breadcrumb/Breadcrumb'
import {
  Image,
} from "../../components/image/Image.js"
import { AboveFold, Section } from "../../components/section/Section.js"
import { TextMedium } from "../../components/text/Text.js"
import GumroadForm from "../../components/gumroadForm/GumroadForm.js"


function ContentGovernanceTemplate({ children, ...rest }) {
  return (
    <Layout>
      <Seo title="Content Governance Model - Template" 
       description="This template details a hybrid content governance model to help organize the content department of a global marketing team."
       image={img1}
       featuredImage={img1} />
       
      <AboveFold isLight>
        <Row>
          <Breadcrumb>
            <li>
              <a href="/content-strategy-templates/">All templates</a>
            </li>
            <li>
              <a href="/content-strategy-templates/content-governance-template/">Content Governance - Template</a>
            </li>
          </Breadcrumb>
        </Row>
        <Row isCentered>
          <Column50>
            <BlogTitle isPrimary title="Content Governance - Template" />
            <SubHeading subheading="This template details a hybrid content governance model to help organize the content department of a global marketing team." />
            <ButtonContainer>
              <Contact buttonText="Get the template" buttonLink="https://socontent.gumroad.com/l/content-governance-model" />
            </ButtonContainer>
          </Column50>
          <Column50>
            <Image src={img1} alt="Content Governance Template" />
          </Column50>
        </Row>
      </AboveFold>

      <Section>  
        <BlogRow isCentered>
          <TextMedium textMedium="This template details a hybrid content governance model to help organize the content department of a global marketing team." />
          <GumroadForm 
            formName="Content Governance Model - Template"
            redirectPage="https://socontent.gumroad.com/l/content-governance-model"
            buttonText="Get it on Gumroad"
            buttonLink="https://socontent.gumroad.com/l/content-governance-model"
            // checkboxText="*Your consent is required. By checking this box, you agree to be contacted by us when we publish similar content." 
          />
        </BlogRow>
      </Section>
    </Layout>
  )
}

export default ContentGovernanceTemplate
